<template>
  <img :src="src"
       :alt="alt"
       :width="scaledWidth"
       :height="scaledHeight"
       ref="scaledImage"
  />
</template>
<script>
export default {
  name: "ScaledImage",
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: false
    },
    width: {
      type: Number,
      required: false
    },
    height: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    image: new Image(),
    scaledWidth: 1,
    scaledHeight: 1,
  }),
  created() {
    const context = this;
    this.image.onload = function() {
      let scale = 1;
      if (context.width) {
        scale = context.width / context.image.naturalWidth;
      } else if (context.height) {
        scale = context.height / context.image.naturalHeight;
      }
      context.scaledWidth = context.image.naturalWidth * scale;
      context.scaledHeight = context.image.naturalHeight * scale;
    }
    this.image.src=this.src;
  }
}
</script>
