<template name="InquiryActions">
<div class="row mb-3 mt-2">
  <div class="col">
    <button
      class="btn btn-primary"
      :disabled="saving || $store.getters.submitted"
      @click="uncheckAllCountries"
    >
      <i class="fa fa-times"></i>&nbsp; Clear Selections
    </button>
  </div>
  <div v-if="swapSaveAndSlot" class="col text-center">
    <slot></slot>
  </div>
  <div v-if="showSave" class="col" :class="{'text-center': !swapSaveAndSlot, 'text-right': swapSaveAndSlot}">
    <span v-if="saving">
      <i class="fa fa-sync fa-spin"></i>&nbsp;Saving Programme...
    </span>
    <tooltip-button
      v-else
      :text="'Save Report' + (inquiryOnlyMode() ? '' : ' Only')"
      icon="save"
      v-show="canSaveReport()"
      btn-class="btn-outline-secondary"
      :disabled="$store.getters.submitted || !capabilitiesFormValid"
      :disabled-tooltip-text="disabledTooltipText"
      disabled-tooltip-level="info"
      disabled-tooltip-position="left"
      @click="saveReport()"
    ></tooltip-button>
  </div>
  <div v-if="!swapSaveAndSlot" class="col">
    <slot></slot>
  </div>
</div>
</template>
<script type="text/javascript">
import FeaturesMixin from "@/mixins/features.mixin";
import TooltipButton from "./TooltipButton";
export default {
  name: "InquiryActions",
  components: { TooltipButton },
  mixins: [FeaturesMixin],
  props: {
    showSave: {
      type: Boolean,
      default: true,
    },
    swapSaveAndSlot: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    saving: false,
  }),
  computed: {
    capabilitiesFormValid() {
      const capabilityFieldsConfig = this.activeCapabilitiesFields();
      const requiredFields = Object.keys(capabilityFieldsConfig).filter(field => capabilityFieldsConfig[field].required);
      for (let field of requiredFields) {
        let value = this.$store.getters[field];
        if (!value || value.length === 0) {
          return false;
        }
      }
      return true;
    },
    disabledTooltipText() {
      if (this.capabilitiesFormValid) {
        return "This programme has been submitted - changes cannot be saved";
      }
      return "There are required fields on the form above that have not been completed";
    },
  },
  methods: {
    uncheckAllCountries() {
      this.$store.dispatch("selectDeselectCountry", {
        countryId: "all",
        checked: false
      });
      this.$store.commit("resetProgramme");
      this.$router.replace({ name: "Country Select" });
    },
    async save() {
      this.saving = true;
      await this.$store.dispatch("storeState");
      this.saving = false;
      await this.$router.push({ name: "Programmes" });
    },
    async saveReport() {
      if (!this.$store.getters.isLoggedIn) {
        this.$bvModal.show("modal-login");
      } else {
        await this.save();
      }
    },
  },
};
</script>
