<template>
  <div class="sounds-like">
    <span v-if="hasSimilar" class="text-secondary">
      Similar to these existing {{ itemLabel }} <em>(click one to select)</em>
      <button v-for="item in similar" :key="item[itemKey]" class="btn btn-sm btn-link" @click="$emit('select', item[itemText])">
        {{ item[itemText] }}
      </button>
    </span>
  </div>
</template>
<script>
export default {
  name: "SoundsLike",
  props: {
    current: {
      type: String,
      default: ""
    },
    list: {
      type: Array,
      default: () => []
    },
    itemKey: {
      type: String,
      default: "id"
    },
    itemText: {
      type: String,
      default: "name"
    },
    itemLabel: {
      type: String,
      default: "Items"
    }
  },
  computed: {
    similar() {
      if (this.current.length < 4) {
        return [];
      }
      // If the current value is in the list (exactly), don't show any similar items
      const exactMatches = this.list.filter(item => item[this.itemText] === this.current);
      if (exactMatches.length) {
        return [];
      }
      return this.list.filter(item => item[this.itemText].toLowerCase().includes(this.current.toLowerCase()));
    },
    hasSimilar() {
      return this.similar.length > 0;
    }
  },
  methods: {
  },
};
</script>
